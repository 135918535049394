import { gql } from '@apollo/client';
import { EnergyDeltaFragment } from '../../fragments/EnergyDelta';
import { BasicAddressFragment, EstimateFragment } from '../../fragments/House';
import { ResultsTextsFragment } from '../../fragments/ResultsTexts';
import { SituationEnergyPricesFragment } from './../../fragments/Situation';

export const RESULTS_PAGE = gql`
  ${BasicAddressFragment}
  ${EstimateFragment}
  ${EnergyDeltaFragment}
  ${SituationEnergyPricesFragment}
  ${ResultsTextsFragment}
  query resultsPage($houseId: ID!) {
    house(id: $houseId) {
      id
      ...BasicAddress
      constructionYear
      type
      comfortScore
      pvSystemPower
      energy {
        gasConsumption
        electricityConsumption
        electricityProduction
        energyIndex
      }
      feasibleSolutions
      installedSolutions
      savingsCheckResultsTexts {
        ...ResultsTexts
      }
      labelRegistration {
        registered
        energyLabel
        energyIndex
      }
      estimates {
        ...Estimate
      }
      crawlspace {
        compartments
      }
      walls {
        area
        rc
        cavity
      }
      floor {
        area
        rc
      }
      roof {
        area
        rc
      }
      heating {
        boilerAge
      }
      windows {
        area
        type
        u
      }
      windowsZTAU {
        livingU
        livingZTA
        sleepingU
        sleepingZTA
        totalSingleArea
        totalDoubleArea
        totalHRArea
      }
      intake {
        id
        type
        constructionYear
        heating {
          boilerAge
        }
      }
      situation {
        id
        currentSlurpers
        energyPrices {
          ...SituationEnergyPrices
        }
        consumption {
          gas
          electricity
        }
        solar {
          currentSystem {
            energyYield
            energyYieldPercentage
          }
        }
        heating {
          boilerInstallationYear
        }
      }
      initialQuestionsAnswered
      requestedDuties {
        name
      }
      isGVAHouse
      isAdaxioHouse
      advice {
        id
        isRequested
        isPaid
        isSent
      }
      address {
        street
        zip
        number
        suffix
      }
      averageZipLabel
      lead {
        id
        motivation
      }
      customer {
        id
        motivation
      }
    }
  }
`;

export const RESULTS_CARDS_STANDALONE = gql`
  ${BasicAddressFragment}
  ${EnergyDeltaFragment}
  ${SituationEnergyPricesFragment}
  ${ResultsTextsFragment}
  ${EstimateFragment}
  query resultsCardsStandalone($houseId: ID!, $isLead: Boolean!, $isCustomer: Boolean!) {
    house(id: $houseId) {
      id
      ...BasicAddress
      constructionYear
      type
      area
      pvSystemPower
      comfortScore
      energy {
        gasConsumption
        electricityConsumption
        electricityProduction
        energyIndex
      }
      feasibleSolutions
      installedSolutions
      savingsCheckResultsTexts {
        ...ResultsTexts
      }
      labelRegistration {
        registered
        energyLabel
        energyIndex
      }
      estimates {
        ...Estimate
      }
      crawlspace {
        compartments
      }
      walls {
        area
        rc
        cavity
      }
      floor {
        area
        rc
      }
      roof {
        area
        rc
      }
      heating {
        boilerAge
      }
      windows {
        area
        type
        u
      }
      windowsZTAU {
        livingU
        livingZTA
        sleepingU
        sleepingZTA
        totalSingleArea
        totalDoubleArea
        totalHRArea
      }
      intake {
        id
        heating {
          boilerAge
        }
      }
      situation {
        id
        currentSlurpers
        consumption {
          electricity
          gas
        }
        energyPrices {
          ...SituationEnergyPrices
        }
        solar {
          currentSystem {
            energyYield
            energyYieldPercentage
          }
        }
        heating {
          boilerInstallationYear
        }
      }
      lead @include(if: $isLead) {
        id
        motivation
      }
      customer @include(if: $isCustomer) {
        id
        motivation
      }
    }
  }
`;

export const RESULTS_USERLESS = gql`
  ${EnergyDeltaFragment}
  ${ResultsTextsFragment}
  query resultsCards_Userless($houseId: ID!) {
    findUserlessHouse(houseId: $houseId) {
      id
      address {
        zip
        street
        number
        suffix
        city
      }
      constructionYear
      type
      pvSystemPower
      comfortScore
      energy {
        gasConsumption
        electricityConsumption
        electricityProduction
        energyIndex
      }
      feasibleSolutions
      installedSolutions
      savingsCheckResultsTexts {
        ...ResultsTexts
      }
      labelRegistration {
        registered
        energyLabel
        energyIndex
      }
      estimates {
        energyDelta {
          ...EnergyDelta
        }
        investment
        solution
        comfortScore
        paybackTime
      }
      crawlspace {
        compartments
      }
      walls {
        area
        rc
        cavity
      }
      floor {
        area
        rc
      }
      roof {
        area
        rc
      }
      heating {
        boilerAge
      }
      windowsZTAU {
        livingU
        livingZTA
        sleepingU
        sleepingZTA
        totalSingleArea
        totalDoubleArea
        totalHRArea
      }
      situation {
        currentSlurpers
        consumption {
          electricity
          gas
        }
        energyPrices {
          gas
          electricity
          discountedElectricity
          gasNetworkOperator
        }
        solar {
          currentSystem {
            panels
          }
        }
      }
    }
  }
`;
